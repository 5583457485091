import {AnimatePresence, motion} from 'motion/react';

const formatMentionCount = (mentionCount: number) => {
	if (mentionCount > 99 && mentionCount < 1000) {
		return '99+';
	}

	if (mentionCount >= 1000) {
		const thousands = Math.floor(mentionCount / 1000);
		return thousands <= 9 ? `${thousands}K+` : '9K+';
	}

	return mentionCount.toString();
};

const getWidth = (mentionCount: number) => {
	if (mentionCount > 9 && mentionCount < 100) {
		return 18;
	}

	if (mentionCount > 99 && mentionCount < 1000) {
		return 24;
	}

	if (mentionCount >= 1000) {
		return 28;
	}

	return 16;
};

export const MentionBadge = ({mentionCount}: {mentionCount: number}) => {
	if (mentionCount === 0) {
		return null;
	}
	return (
		<div
			className="flex h-4 flex-shrink-0 items-center justify-center rounded-lg bg-status-danger pb-px text-center font-bold text-white text-xs leading-4"
			style={{width: getWidth(mentionCount)}}
		>
			{formatMentionCount(mentionCount)}
		</div>
	);
};

export const MentionBadgeAnimated = ({mentionCount}: {mentionCount: number}) => (
	<AnimatePresence>
		{mentionCount > 0 && (
			<motion.div
				animate={{opacity: 1, scale: 1}}
				exit={{opacity: 0, scale: 0}}
				initial={{opacity: 1, scale: 1}}
				transition={{type: 'spring', stiffness: 500, damping: 20}}
			>
				<MentionBadge mentionCount={mentionCount} />
			</motion.div>
		)}
	</AnimatePresence>
);
